import React from 'react'
import Helmet from 'react-helmet'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Layout from '../layouts'
import Feature from '../components/Feature'
import Accreditations from '../components/accreditations'

const meta = {
  title: 'Policies',
  url: 'https://www.maxpowerlimited.com/policies/',
}

const PoliciesPage = props => (
  <Layout {...props}>
    <Helmet
      title={meta.title}
      meta={[
        { itemprop: 'name', content: meta.title },
        { property: 'og:title', content: meta.title },
        { property: 'og:url', content: meta.url },
        { name: 'twitter:title', content: meta.title },
        { name: 'twitter:url', content: meta.url },
      ]}
      link={[{ rel: 'canonical', href: meta.url }]}
    />

    <Feature title={meta.title} />

    <div className="page">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-lg-8">
            <h2>Policies</h2>

            <p>
              Maxpower Limited is a responsible credited organisation and our
              accreditations and awards are evidence of this. Our company
              policies and company practices connect the workforce of our
              organisation, to ensure that we continually fulfil our compliance
              requirements, whilst carrying out our operations as efficiently
              and economically as possible.
            </p>

            <p>
              Maxpower Limited pride themselves on their commitment to customer
              satisfaction and continual improvement.
            </p>

            <p>
              Our policies are defined by senior management within the company
              and reviewed annually to ensure that they are aligned with current
              industry requirements and legislation.
            </p>

            <Accreditations />

            <div className="policies">
              <a
                href="/policies/Maxpower-Limited-Privacy-Policy-Updated-24th-May-2018.docx"
                title="Download our Privacy Policy"
                className="btn btn-secondary"
              >
                <FontAwesomeIcon icon="file-alt" /> Privacy Policy
              </a>

              <a
                href="/policies/Maxpower-Limited-Policy-Statement-2018.pdf"
                title="Download our Policy Statement"
                className="btn btn-secondary"
              >
                <FontAwesomeIcon icon="file-alt" /> Policy Statement
              </a>

              <a
                href="/policies/Maxpower-Limited-Quality-Policy.docx"
                title="Download our Quality Policy"
                className="btn btn-secondary"
              >
                <FontAwesomeIcon icon="file-alt" /> Quality Policy
              </a>

              <a
                href="/policies/WasteCarrierRegistrationCertificate-CBDL214393.pdf"
                title="Download our Waste Carrier Registration Certificate"
                className="btn btn-secondary"
              >
                <FontAwesomeIcon icon="file-alt" /> Waste Carrier Registration
                Certificate
              </a>

              <a
                href="/policies/Carbon-Management-Policy.doc"
                title="Download our Carbon Management Policy"
                className="btn btn-secondary"
              >
                <FontAwesomeIcon icon="file-alt" /> Carbon Management Policy
              </a>

              <a
                href="/policies/Maxpower-Limited-Environmental-Policy.doc"
                title="Download our Environmental Policy"
                className="btn btn-secondary"
              >
                <FontAwesomeIcon icon="file-alt" /> Environmental Policy
              </a>

              <a
                href="/policies/Modern-Slavery-and-Human-Trafficking-Statement-MPO1.docx"
                title="Download our Modern Slavery &amp; Human Trafficking Statement"
                className="btn btn-secondary"
              >
                <FontAwesomeIcon icon="file-alt" /> Modern Slavery &amp; Human
                Trafficking Statement
              </a>
            </div>
          </div>
          <div className="d-none d-lg-block col-lg-3 offset-lg-1">
            <p>
              <span className="tagline">Electrical compliance services:</span>
              <span className="phone">020 3633 0956</span>
            </p>

            <h3>Specialist Sectors</h3>
            <ul className="service-list">
              <li>Education</li>
              <li>Retail</li>
              <li>Care homes</li>
              <li>Health &amp; Leisure</li>
              <li>Facilities Partners</li>
              <li>Social Housing</li>
              <li>Office &amp; Factories</li>
              <li>Warehousing</li>
              <li>Pubs &amp; Clubs</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default PoliciesPage
